import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FinanceSettings } from '../common/models';
import { FAQ } from '../feature/dashboard/dashboard/models/faq-item.model';
import { PortalSettings } from '../common/models/portalSettings.model';
import { ChartApiData } from '../feature/dashboard/dashboard/chart/models/chart.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private apiUrl = `${environment.apiUrl}/settings`;

  constructor(private readonly http: HttpClient) {}

  getWeb3Settings(): Observable<FinanceSettings> {
    return this.http.get<FinanceSettings>(`${this.apiUrl}/web3Settings`);
  }

  getFaqItems(): Observable<FAQ> {
    return this.http.get<FAQ>(`${this.apiUrl}/faq`);
  }

  getPortalSettings(): Observable<PortalSettings> {
    return this.http.get<PortalSettings>(`${this.apiUrl}/portal-settings`);
  }

  getChartData(period: string): Observable<ChartApiData[]> {
    return this.http.get<ChartApiData[]>(`${this.apiUrl}/chart`, {
      params: { period },
    });
  }
}
